<template>
  <div>
    <v-card>
      <div
        class="pa-5
      mb-5"
      >
        <h2 class="title">
          Charte Informatique - Plateforme de gestion des adhésions et abonnements : FCPE-ADHESIONS
        </h2>

        <h3>ARTICLE 1 : Généralités</h3>
        <h4>1.1 - Objet de la charte</h4>
        <p>
          La présente charte informatique a pour objet d'établir un cadre clair et transparent des règles de
          confidentialité de la plateforme de gestion d'adhésions / abonnements « FCPE-ADHESIONS » d'une part, et des
          autres données informatiques que les adhérents ou les salariés de la FCPE sont amenés à traiter ou à connaître
          dans le cadre de leur fonction.
        </p>
        <h4>1.2 Déclaration CNIL</h4>
        <p>
          La plateforme de gestion d'adhésions / abonnements « FCPE-ADHESIONS » est enregistrée à la CNIL
          sous le numéro suivant : 1399754, en date du 30 avril 2009.
        </p>
        <h4>MODALITES D'APPLICATION DE LA CHARTE</h4>
        <p>
          Nous vous demandons d'accepter la charte d'utilisation de la plateforme de gestion des adhésions et
          abonnements en cochant la case tout en bas de cet écran
        </p>

        <h3>ARTICLE 2 : RESPECT DE LA CONFIDENTIALITE DES INFORMATIONS</h3>
        <h3>2.1 DES TIERS</h3>
        <p>
          La gestion des adhérents de la FCPE est informatisée, ce qui signifie que sont enregistrées, sur support
          informatique, des informations à caractère personnel les concernant. Ces informations peuvent paraître
          banales : un nom, un prénom, une adresse.
        </p>
        <p>
          Mais parce qu'il s'agit d'informations sur les membres de notre association, elles relèvent un choix
          individuel, l'exercice d'une liberté publique, en tout cas un engagement et plus d'un fois une préférence. Pour
          toutes ces raisons, ces informations doivent être protégées.
        </p>
        <p>
          Tous les utilisateurs sont donc tenus de prendre toutes les précautions utiles pour préserver la sécurité
          des données de la FCPE et, notamment, empêcher qu'elles soient déformées, endommagées, ou que des tiers
          y aient accès.
        </p>
        <p>
          En particulier l'exportation de listes d'adhérents à partir de la plateforme d'adhésions / abonnements
          « FCPE-ADHESIONS » doit être faite exclusivement pour l'usage de la FCPE et non pas pour des tiers, que ces
          tiers soient des entreprises commerciales ou des associations, même proches des principes de la FCPE.
        </p>
        <p>
          Afin de protéger ces données, et ceci de façon transparente, nous vous informons que la plateforme
          de gestion d'adhésions / abonnements « FCPE-ADHESIONS » conserve les informations de connexion, ainsi que
          celles concernant les exportations des fichiers et les créations/modifications/suppression d'objets.
        </p>
        <p>
          Le droit d'accès (login et mot de passe) au logiciel de gestion des adhérents est, à ces effets, concédé
          à titre strictement individuel.
        </p>
        <h3>2.2 DES AUTRES UTILISATEURS</h3>
        <p>
          Les utilisateurs ne doivent pas tenter de lire, de copier, de divulguer ou de modifier les fichiers d'un autre
          utilisateur sans y avoir été autorisés, et doivent s'interdire toute tentative d'interception de communications
          entre tiers.
        </p>
        <h4>2.3 DE LA FCPE</h4>
        <p>
          Les utilisateurs sont tenus à la réserve d'usage sur toute information relative au fonctionnement interne
          de la FCPE qu'ils auraient pu obtenir en utilisant les ressources informatiques.
        </p>
        <h3>ARTICLE 3 – SANCTIONS</h3>
        <p>
          L'accès aux ressources informatiques ne pourra se faire qu'après acception des modalités convenues
          dans la présente charte. Le non-respect de ces dispositions ou des règles et lois en vigueur engagera la
          responsabilité de l'utilisateur.
        </p>

        <v-checkbox
          v-model="conventionAccepted"
          label="Accepter la charte d'utilisation de la plateforme FCPE Adhésion"
        />
        <v-btn
          class="mr-2"
          color="primary"
          @click="unblockedUser"
        >
          Enregistrer
        </v-btn>
      </div>
    </v-card>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import xhr from '../../plugins/axios';

export default {
  name: 'BlocageForm',
  data: () => ({
    conventionAccepted: false,
  }),
  computed: {
    ...mapGetters('session', {
      user: 'getUser',
    }),
  },
  methods: {
    ...mapActions('session', {
      setErrors: 'setErrors',
    }),
    async unblockedUser() {
      if (this.conventionAccepted) {
        await xhr.patch(`api/admin/parametrages/utilisateur/${this.user.idFCPEAdhesion}/unblocked`);
        window.location.href = '/'; 
      } else {
        this.setErrors({ info: 'Acceptez la charte d\'utilisation de la plateforme FCPE Adhésions pour accéder à l\'application' });
      }  
    },
  },
};

</script>