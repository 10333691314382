<template>
  <BlocageForm />
</template>
<script>
import BlocageForm from '../../components/utilisateurs/Blocage';


export default {
  name: 'BlocageContainer',

  components: {
    BlocageForm,
  },
};
</script>